import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { makeClient } from './utilities/HasuraClient';
import React, { useCallback, useEffect, useState } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import Loading from './components/Loading';

const API_URL = process.env.REACT_APP_REPORT_SERVER;

const initCubejsApi = (accessToken) => {
  return cubejs({
    apiUrl: `${API_URL}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Logout = React.lazy(() => import('./views/Pages/Logout'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = () => {
  const [cubejsApi, setCubejsApi] = useState(null);
  const [apolloClient, setApolloClient] = useState(null);

  // Get all necessary auth0 data
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  // Force to work only for logged in users bye checking isAuthenticated
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Redirect not logged users
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  // Get CubeJS instance with access_token and set to component state
  const initCubejs = useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      audience: 'test',
      scope: 'openid profile email',
    });

    setCubejsApi(initCubejsApi(accessToken));
  }, [getAccessTokenSilently]);

  const initApolloClient = useCallback(async () => {
    setApolloClient(
      makeClient(
        await getAccessTokenSilently({
          audience: 'test',
          scope: 'openid profile email',
        }),
      ),
    );
  }, [getAccessTokenSilently]);

  // Init CubeJS instance with access_token
  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return;
    }

    if (!cubejsApi) {
      initCubejs();
    }

    if (!apolloClient) {
      initApolloClient();
    }
  }, [
    cubejsApi,
    apolloClient,
    initCubejs,
    initApolloClient,
    isAuthenticated,
    isLoading,
  ]);

  if (error) {
    return <span>{error.message}</span>;
  }

  // show loading indicator while loading
  if (isLoading || !isAuthenticated || !cubejsApi || !apolloClient) {
    return <span>Loading</span>;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <CubeProvider cubejsApi={cubejsApi}>
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/logout"
                name="Logout Page"
                render={(props) => <Logout {...props} />}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              {isAuthenticated && (
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              )}
              <Redirect to="/login" />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </CubeProvider>
    </ApolloProvider>
  );
};

export default App;
