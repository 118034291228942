import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = (token) =>
  new HttpLink({
    uri: process.env.REACT_APP_HASURA_URL,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

const wsLink = (token) => {
  const uri = process.env.REACT_APP_HASURA_URL.includes('https')
    ? process.env.REACT_APP_HASURA_URL.replace(/^https?/, 'wss')
    : process.env.REACT_APP_HASURA_URL.replace(/^http?/, 'ws');
  return new WebSocketLink({
    uri,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    },
  });
};

const splitLink = (authHeader) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(authHeader),
    httpLink(authHeader),
  );

export const makeClient = (/** @type {string} */ authHeader) => {
  return new ApolloClient({
    link: splitLink(authHeader),
    cache: new InMemoryCache(),
  });
};
